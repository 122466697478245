import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import apiClient from 'helpers/api-client'
import { Link, useMediaQuery, useTheme } from '@mui/material'
import redirect from 'helpers/redirect'
import { useRouter } from 'next/router'
import SectionContainer from '../../SectionContainer/v2'
import DesktopContentSlider from 'components/domains/Home/DesktopContentSlider'
import MobileContentSlider from 'components/domains/Home/MobileContentSlider'
import CreatorCaption from 'components/commons/CreatorCaption'
import imageResizer from 'helpers/image-resizer'
import { getSectionDetail } from 'services/campaign'
import trackEvent from 'src/trackers'
import isEmpty from 'helpers/is-empty'
import { isGuestMode } from 'helpers/cookie'
import handleErrorImage from 'helpers/error-image'
import Box from 'components/commons/Box'
import Typography from 'components/commons/Typography'
import Stack from 'components/commons/Stack'
import Grid from 'components/commons/Grid'
import SeeAllCard from 'components/commons/Card/SeeAllCard'
import PrivateSessionSkeleton from '../Skeleton'
import { useTranslation } from 'react-i18next'

const PrivateSessionListItem = styled(Box)(({ theme }) => ({
  width: `140px`,
  height: `140px`,
  borderRadius: '12px',
  display: 'inline-block',
  marginRight: '8px',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '75px',
    background:
      'linear-gradient(360deg, rgba(22, 26, 34, 0.75) 0%, rgba(22, 26, 34, 0) 100%)',
    bottom: 0,
    left: 0,
    zIndex: 1,
    borderRadius: '8px',
  },
  div: {
    zIndex: 2,
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '10px 12px',
    h4: {
      ...theme.typography.normalBold,
      letterSpacing: '0.0015em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.text.primary,
    },
    h6: {
      ...theme.typography.smallRegular,
      letterSpacing: '0.0025em',
      color: theme.palette.text.primary,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  '@media only screen and (min-width: 900px)': {
    width: '160px',
    height: '160px',
  },
  '@media only screen and (min-width: 1120px)': {
    width: '100%',
    height: '100%',
  },
  [theme.breakpoints.down('md')]: {
    margin: 0,
    width: '188px',
    height: '188px',
  },
}))

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  objectFit: 'cover',
  objectPosition: 'center',
  borderRadius: '8px',
})

type IPrivateSessionsSliderProps = {
  title?: string
  isThematicCampaign?: boolean
  sectionId?: string
  sectionType?: string
}

function PrivateSessionSlider({
  title,
  isThematicCampaign,
  sectionId,
  sectionType,
}: IPrivateSessionsSliderProps) {
  const { t } = useTranslation()

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const [privateSessions, setPrivateSessions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const { pathname, query } = useRouter()
  const trackerData = { pathname, query }

  function getListFromAPI(page: number = 1) {
    return apiClient({
      url: `/user/guest/v1/creator/privatelist`,
      method: 'GET',
      params: {
        page: page,
        limit: 15,
      },
    })
  }

  useEffect(() => {
    async function getPrivateSessionList() {
      try {
        setIsLoading(true)
        if (isThematicCampaign) {
          const url = `/campaign${
            isGuestMode() ? '/guest' : ''
          }/v1/section/${sectionId}?`
          const sessionList = await getSectionDetail(url, {
            page: 1,
            limit: 15,
          })
          setPrivateSessions(
            sessionList?.data?.data?.one_on_one_list || [],
          )
        } else {
          const sessionList = await getListFromAPI(1)
          setPrivateSessions(sessionList.data.data)
        }
      } catch (err) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    }

    getPrivateSessionList()
  }, [])

  const renderSessionItem = (creator) => {
    return (
      <PrivateSessionListItem
        className={`private-session-list-item c-${
          isThematicCampaign ? 'thematic' : 'home'
        }-private-session-card`}
        key={`private-session-${creator.creator_id}`}
        id={`b-${
          isThematicCampaign ? 'thematic' : 'home'
        }-private-session-${creator.creator_id}`}
        sx={{
          cursor: 'pointer',
          backgroundPosition: 'center',
        }}
        onClick={() => {
          trackEvent.home('select_creator', trackerData, {
            state_sub_name: '1_on_1_session',
            section_id: !isEmpty(sectionId) ? sectionId : undefined,
            section_title: !isEmpty(title) ? title : undefined,
            section_content_type: !isEmpty(sectionType)
              ? sectionType
              : undefined,
            creator_id: creator.creator_id,
            creator_user_id: creator.creator_user_id,
            creator_category_id: String(creator.category.id),
            creator_category: creator.category.name,
          })
          redirect(
            `/p/${creator.username}?source=${pathname}&tab=privateSession`,
          )
        }}
      >
        <StyledImage
          alt="cover-image"
          src={imageResizer(creator.header_image, '500w')}
          onError={({ currentTarget }) => {
            handleErrorImage({ currentTarget, type: 'default' })
          }}
        />
        <Link
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
          }}
          href={`/p/${creator.username}?source=${pathname}&tab=privateSession`}
        />
        <Box
          sx={(theme) => ({
            background: theme.palette.tiptip[500_83],
            height: {
              xs: '45%',
              md: '35%',
            },
            borderBottomLeftRadius: '12px',
            borderBottomRightRadius: '12px',
          })}
        >
          <CreatorCaption
            isTruncate
            title={creator.name}
            isVerified={creator.verified}
            size="sm"
            sx={{ color: 'white' }}
          />
          <Typography variant="smallRegular" sx={{ color: 'white' }}>
            {creator.category.name}
          </Typography>
        </Box>
      </PrivateSessionListItem>
    )
  }

  const renderItemCarousel = () => {
    let sessionItem = []
    let sessionContainer = []
    const isShowSeeAllCard = privateSessions.length > 6
    let newDataArray = isShowSeeAllCard
      ? [...privateSessions, {}]
      : privateSessions

    if (newDataArray?.length) {
      for (let index = 0; index < newDataArray.length; index++) {
        const session = newDataArray[index]
        const element = (
          <Grid
            item
            md={2}
            lg={2}
            key={session.id}
            sx={{ height: '188px' }}
          >
            {isShowSeeAllCard && index === newDataArray.length - 1 ? (
              <SeeAllCard
                name={title}
                url={`/section/${sectionId}?source=${pathname}&source_action=click_see_all`}
              />
            ) : (
              renderSessionItem(session)
            )}
          </Grid>
        )

        sessionItem.push(element)
        if (
          sessionItem.length === 6 ||
          index === newDataArray.length - 1
        ) {
          const container = (
            <Grid
              key={`container-${index}`}
              container
              columnSpacing={{ xs: 1, md: 2, lg: 2 }}
              rowGap={{ xs: 1, md: 2, lg: 2 }}
            >
              {sessionItem.map((item) => item)}
            </Grid>
          )
          sessionContainer.push(container)
          sessionItem = []
        }
      }

      return sessionContainer.map((item) => item)
    }
  }

  if ((!isLoading && privateSessions?.length === 0) || isError) {
    return null
  }

  return (
    <>
      {isLoading && <PrivateSessionSkeleton />}
      {!isLoading && privateSessions?.length > 0 && (
        <SectionContainer
          sectionName={
            isThematicCampaign ? title : t('oneOn1Session')
          }
          subtitle={t('oneOn1SessionDesc')}
          sxContainer={{
            padding: isDesktop
              ? '0px 128px !important'
              : '0px 16px !important',
          }}
        >
          <>
            <DesktopContentSlider>
              {renderItemCarousel()}
            </DesktopContentSlider>
            <MobileContentSlider numOfRow={1}>
              <Stack
                direction={'row'}
                sx={{ padding: '4px', gap: '8px' }}
              >
                {privateSessions.map((creator) => {
                  return renderSessionItem(creator)
                })}
              </Stack>
            </MobileContentSlider>
          </>
        </SectionContainer>
      )}
    </>
  )
}

export default PrivateSessionSlider
